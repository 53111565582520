import { FC } from 'react';
import { useParams } from 'next/navigation';
import { LineItem } from 'shared/types/cart/LineItem';
import Typography from 'components/commercetools-ui/atoms/typography';
import { CurrencyHelpers } from 'helpers/currencyHelpers';
import { useFormat } from 'helpers/hooks/useFormat';
import Image from 'frontastic/lib/image';

type OrderItemProps = {
  lineItem: LineItem;
  additionalClassName?: string;
};

const OrderItem: FC<OrderItemProps> = ({ lineItem, additionalClassName = '' }) => {
  const { locale } = useParams();
  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });

  return (
    <div key={lineItem.lineItemId} className={`flex justify-start border-b py-16 ${additionalClassName}`}>
      {lineItem.variant?.images?.[0] && (
        <div className="relative h-[104px] w-[88px] shrink-0">
          <Image
            cloudimage={true}
            fill
            style={{ objectFit: 'contain' }}
            src={lineItem.variant.images[0].src}
            alt={lineItem.variant.images[0].alt}
          />
        </div>
      )}
      <div className="flex flex-col justify-center pl-16">
        <Typography className="uppercase text-neutral-4">{lineItem?.name}</Typography>
        <Typography className="mt-8 font-medium text-neutral-4">
          {CurrencyHelpers.formatForCurrency(lineItem?.totalPrice as number, locale)}
        </Typography>
        <Typography className="mt-8 text-neutral-4">
          {`${lineItem?.count} ${formatProductMessage({
            id: 'baseUnit',
            defaultMessage: 'szt.',
          })}`}
        </Typography>
      </div>
    </div>
  );
};

export default OrderItem;
