import React, { useMemo } from 'react';
import { redirect, useSearchParams } from 'next/navigation';
import Skeleton from 'react-loading-skeleton';
import Breadcrumb from 'components/commercetools-ui/atoms/breadcrumb';
import Button from 'components/commercetools-ui/atoms/button';
import Container from 'components/commercetools-ui/atoms/container';
import Dropdown from 'components/commercetools-ui/atoms/dropdown';
import Link from 'components/commercetools-ui/atoms/link';
import Typography from 'components/commercetools-ui/atoms/typography';
import ChevronDownIcon from 'components/icons/ChevronDownIcon';
import HomeIcon from 'components/icons/HomeIcon';
import useClassNames from 'helpers/hooks/useClassNames';
import { useFormat } from 'helpers/hooks/useFormat';
import useHash from 'helpers/hooks/useHash';
import usePagePathWithLocale from 'helpers/hooks/usePagePathWithLocale';
import { Reference } from 'types/reference';
import { useAccount } from 'frontastic';
import Addresses from './sections/addresses';
import AddressModal from './sections/addresses/addressModal';
import CustomerSupport from './sections/customer-support';
import MyAccount from './sections/my-account';
import Orders, { OrderStatus } from './sections/orders';
import OrderPage from './sections/orders/order-page';

export interface AccountTab {
  name: string;
  href: string;
  isActive: boolean;
  isContactInformation?: boolean;
}
export interface FAQ {
  question: string;
  answer: string;
}

export interface OrderHistory {
  paymentStatusPaidHeadline: string;
  paymentStatusOpenHeadline: string;
  paymentStatusOpenDescription: string;
  returnAndComplaintsHeadline: string;
  returnAndComplaintsDescription: string;
  orderCancellationHeadline: string;
  orderCancellationDescription: string;
  orderModificationHeadline: string;
  orderModificationDescription: string;
  payNowButtonLabel: string;
}

export interface OrderStatuses {
  orderStatusUnpaidHeadline: string;
  orderStatusUnpaidDescription: string;
  orderStatusPaidHeadline: string;
  orderStatusPaidDescription: string;
  orderStatusBeingCompletedHeadline: string;
  orderStatusBeingCompletedDescription: string;
  orderStatusTransferredHeadline: string;
  orderStatusTransferredDescription: string;
  orderStatusDeliveredHeadline: string;
  orderStatusDeliveredDescription: string;
  orderStatusDeliveryRejectedHeadline: string;
  orderStatusDeliveryRejectedDescription: string;
}
export interface AccountDetailsProps {
  loginLink?: Reference;
  phoneNumber: string;
  workingHoursWeekdays: string;
  workingHoursWeekends: string;
  email: string;
  addressLine: string;
  cityAndPostalCode: string;
  country: string;
  faqs: FAQ[];
  orderHistory?: OrderHistory[];
  orderStatuses?: OrderStatuses[];
  modificationOfOrders?: string;
}

export interface OrderHistoryOverviewProps {
  orderStatuses: OrderStatuses;
  modificationOfOrders: string;
}

const AccountDetails: React.FC<AccountDetailsProps> = ({
  phoneNumber,
  workingHoursWeekdays,
  workingHoursWeekends,
  email,
  addressLine,
  cityAndPostalCode,
  country: organizationCountry,
  faqs,
  orderHistory,
  orderStatuses,
  modificationOfOrders,
}) => {
  const { accountPagePath, loginPagePath, homePagePath } = usePagePathWithLocale();
  const searchParams = useSearchParams();

  const verify = searchParams.get('verify');

  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
  const { formatMessage: formatCustomerSupportMessage } = useFormat({ name: 'customer-support' });
  const [hash, id] = useHash();
  const isLoading = useMemo(() => !!verify, [verify]);

  const { account: user } = useAccount();

  // If user not logged in, redirect to login page
  if (user === undefined) redirect(loginPagePath);

  const statusesOrder = useMemo(() => {
    return orderStatuses
      ? ([
          {
            status: orderStatuses?.[0].orderStatusUnpaidHeadline,
            description: orderStatuses?.[0].orderStatusUnpaidDescription,
          },
          {
            status: orderStatuses?.[0].orderStatusPaidHeadline,
            description: orderStatuses?.[0].orderStatusPaidDescription,
          },
          {
            status: orderStatuses?.[0].orderStatusBeingCompletedHeadline,
            description: orderStatuses?.[0].orderStatusBeingCompletedDescription,
          },
          {
            status: orderStatuses?.[0].orderStatusTransferredHeadline,
            description: orderStatuses?.[0].orderStatusTransferredDescription,
          },
          {
            status: orderStatuses?.[0].orderStatusDeliveredHeadline,
            description: orderStatuses?.[0].orderStatusDeliveredDescription,
          },
          {
            status: orderStatuses?.[0].orderStatusDeliveryRejectedHeadline,
            description: orderStatuses?.[0].orderStatusDeliveryRejectedDescription,
          },
        ] as OrderStatus[])
      : [];
  }, [orderStatuses]);

  const tabs = useMemo<AccountTab[]>(() => {
    return [
      {
        name: formatAccountMessage({ id: 'my.account', defaultMessage: 'My Account' }),
        href: '?',
        isActive: hash === '',
        isContactInformation: true,
      },
      {
        name: formatAccountMessage({ id: 'addresses', defaultMessage: 'Addresses' }),
        href: '?hash=addresses',
        isActive: hash === 'addresses',
      },
      {
        name: formatAccountMessage({ id: 'orders', defaultMessage: 'Orders' }),
        href: '?hash=orders',
        isActive: hash === 'orders',
      },
      {
        name: formatAccountMessage({ id: 'customer.support', defaultMessage: 'Customer support' }),
        href: '?hash=support',
        isActive: hash === 'support',
      },
    ];
  }, [formatAccountMessage, hash]);

  const paths = useMemo(() => {
    const paths = [
      {
        name: formatAccountMessage({ id: 'account', defaultMessage: 'Account' }),
        href: accountPagePath,
      },
    ];
    const tabIndex = tabs?.findIndex((tab) => tab.isActive);
    if (tabs[tabIndex]) paths.push({ name: tabs[tabIndex].name, href: tabs[tabIndex].href });

    if (id && (id.startsWith('address') || id.startsWith('order'))) {
      paths.push({
        name: formatAccountMessage({ id: id.split('_')[0], defaultMessage: id.split('_')[0] }),
        href: `${accountPagePath}${tabs[tabIndex].href}/id=${id}`,
      });
    }
    return paths;
  }, [formatAccountMessage, accountPagePath, tabs, id]);

  const account = useMemo(() => <MyAccount isLoading={isLoading} />, [isLoading]);

  const addresses = useMemo(() => {
    return id?.startsWith('address') ? (
      <AddressModal editedAddressId={id.indexOf('_') === -1 ? undefined : id.slice(id.indexOf('_') + 1)} />
    ) : (
      <Addresses />
    );
  }, [id]);

  const orders = useMemo(() => {
    return id && id.startsWith('order') ? (
      <OrderPage studioData={orderHistory} orderId={id.split('_')[1]} />
    ) : (
      <Orders orderStatuses={statusesOrder} modificationOfOrders={modificationOfOrders} />
    );
  }, [id, modificationOfOrders, orderHistory, statusesOrder]);

  const mapping = {
    '': account,
    addresses: addresses,
    orders: orders,
    support: (
      <CustomerSupport
        phoneNumber={phoneNumber}
        workingHoursWeekdays={workingHoursWeekdays}
        workingHoursWeekends={workingHoursWeekends}
        email={email}
        addressLine={addressLine}
        cityAndPostalCode={cityAndPostalCode}
        country={organizationCountry}
        faqs={faqs}
      />
    ),
  };

  // Title used on the mobile dropdown
  const contentTitle = useMemo(() => {
    const tabIndex = tabs?.findIndex((tab) => tab.isActive);
    if (tabs[tabIndex]) return tabs[tabIndex].name ?? '';
    else return '';
  }, [tabs]);

  // Title that appears at the top of the content container
  const title = useMemo(() => {
    const tabIndex = tabs?.findIndex((tab) => tab.isActive);
    if (tabs[tabIndex].isContactInformation) {
      return `${formatAccountMessage({ id: 'hello', defaultMessage: 'Hi, ' })}${user?.firstName ?? ''}`;
    } else {
      return contentTitle;
    }
  }, [contentTitle, formatAccountMessage, user?.firstName, tabs]);

  const Content = mapping[hash as keyof typeof mapping];

  const tabButtonClassNames = useClassNames(['whitespace-nowrap', isLoading ? 'cursor-default' : '']);

  const buttonClassNames = () => {
    return `!w-full text-14 py-8 px-20 max-h-300  rounded-full border bg-neutral-5`;
  };

  const accountTabButton = useMemo(() => {
    return (
      <div className="flex justify-between">
        <Typography className="text-neutral-4">{contentTitle}</Typography>
        <ChevronDownIcon />
      </div>
    );
  }, [contentTitle]);

  return (
    <Container>
      <div className="md:mx-46 md:mb-40 md:mt-12">
        {/* Breadcrumb */}
        <div className="flex items-center">
          <Link link={homePagePath} className="hidden lg:block">
            <Button
              variant="primaryIconOnly"
              icon={<HomeIcon scale={0.84} />}
              className="!bg-base-accent-2 p-6 text-neutral-6"
            />
          </Link>
          <Breadcrumb className="!mx-4 hidden leading-none lg:block [&>ol]:!pl-0" isBreadcrumb>
            {paths.map((path) => (
              <Link key={path.name} link={path.href} className="text-12">
                {path.name}
              </Link>
            ))}
          </Breadcrumb>
        </div>

        {/* Content */}
        {/* Sidebar on mobile view */}
        <div className="flex flex-col gap-20 bg-base-accent-3 px-20 pb-20 pt-32 lg:hidden">
          <p className="text-24 font-bold leading-[30px] text-neutral-4">{title}</p>

          <Dropdown customButtonClassNames={buttonClassNames} customButtonElement={accountTabButton}>
            {tabs.map((tab) => (
              <Link link={isLoading ? '' : tab.href} key={tab.name} className={tabButtonClassNames}>
                {isLoading ? (
                  <Skeleton />
                ) : (
                  <div className={`px-16 py-8 ${tab.isActive ? 'bg-base-accent-1' : ''}`}>
                    <Typography className={`text-14 font-bold ${tab.isActive ? 'text-neutral-5' : 'text-neutral-4'}`}>
                      {tab.name}
                    </Typography>
                  </div>
                )}
              </Link>
            ))}
          </Dropdown>

          {hash.startsWith('order') && (
            <p className="text-16 leading-6">
              {formatAccountMessage({
                id: 'order.sidebar.description',
                defaultMessage: 'Check status of recent orders, manage your returns and download invoices.',
              })}
            </p>
          )}
          {hash.startsWith('address') && (
            <p className="text-16 leading-6">
              {formatAccountMessage({
                id: 'address.desc',
                defaultMessage: 'Manage or add addresses for your account.',
              })}
            </p>
          )}
          {hash.startsWith('support') && (
            <p className="text-16 leading-6">
              {formatCustomerSupportMessage({ id: 'help.question', defaultMessage: 'How can we help you today?' })}
            </p>
          )}
          {hash === '' && (
            <p className="text-16 leading-6">
              {formatAccountMessage({
                id: 'account.sidebar.description',
                defaultMessage: 'Manage your account details.',
              })}
            </p>
          )}
        </div>

        <div className="relative mb-24 flex gap-12 md:mb-0 md:mt-12">
          {/* Sidebar, hidden on xs, sm */}
          <div className="hidden w-[25%] lg:block">
            <div className="flex flex-col gap-4 bg-base-accent-3 px-20 py-24">
              {tabs.map((tab) => (
                <Link link={isLoading ? '' : tab.href} key={tab.name} className={tabButtonClassNames}>
                  {isLoading ? (
                    <Skeleton />
                  ) : (
                    <div className={`px-16 py-8 ${tab.isActive ? 'bg-base-accent-1' : ''}`}>
                      <Typography className={`font-bold ${tab.isActive ? 'text-neutral-5' : 'text-neutral-4'}`}>
                        {tab.name}
                      </Typography>
                    </div>
                  )}
                </Link>
              ))}
            </div>
          </div>

          {/* Right side, main content */}
          <div className="flex w-full flex-col bg-base-accent-3">
            {/* Content */}
            <div key={hash}>{Content && Content}</div>
          </div>
        </div>
      </div>
    </Container>
  );
};
export default AccountDetails;
