import React, { FC, useState } from 'react';
import { Invoice, Order } from 'shared/types/cart/Order';
import Button from 'components/commercetools-ui/atoms/button';
import Typography from 'components/commercetools-ui/atoms/typography';
import { useFormat } from 'helpers/hooks/useFormat';
import { sdk } from 'sdk';

export interface Props {
  order: Order;
  shippingInfo: string;
  paymentInfo: string;
  shippingAddress: string;
}

const OrderInfoSection: FC<Props> = ({ order, shippingInfo, paymentInfo, shippingAddress }) => {
  const { formatMessage: formatOrdersMessage } = useFormat({ name: 'orders' });
  const [loading, setLoading] = useState(false);

  let addressName =
    order.shippingAddress?.firstName && order.shippingAddress?.lastName
      ? `${order.shippingAddress.firstName} ${order.shippingAddress.lastName}`
      : '';

  if (order.shippingAddress?.additionalStreetInfo) {
    addressName = order.shippingAddress.additionalStreetInfo;
  }

  const openInvoice = async () => {
    setLoading(true);

    const response = await sdk.callAction<Invoice>({
      actionName: 'cart/getInvoiceBase64',
      payload: { filename: order.invoiceFilename },
    });

    if (response.isError) {
      return;
    }

    const link = document.createElement('a');
    link.href = `data:application/octet-stream;base64,${response.data.invoiceBase64}`;
    link.download = `selgros24_faktura_${order.orderId}.pdf`;
    link.click();
    setLoading(false);
  };

  return (
    <div className="flex flex-col gap-32 bg-base-accent-3 px-12 py-32 md:px-20">
      <div className="flex flex-col gap-20">
        <div className="flex">
          <Typography className="min-w-[165px] whitespace-nowrap text-16 leading-6 lg:min-w-[218px]">
            {formatOrdersMessage({
              id: 'shipping.method',
              defaultMessage: 'Shipping method',
            })}
          </Typography>

          <Typography className="text-16 leading-6 text-neutral-4">{shippingInfo}</Typography>
        </div>

        <div className="flex">
          <Typography className="min-w-[165px] text-16  leading-6 lg:min-w-[218px]">
            {formatOrdersMessage({
              id: 'shipping.address',
              defaultMessage: 'Shipping address',
            })}
          </Typography>

          <div>
            <Typography className="text-16 font-bold leading-6 text-neutral-4">{addressName}</Typography>
            {shippingAddress &&
              shippingAddress.split(',').map((line) => (
                <Typography key={line} className="text-16 leading-loose text-neutral-4">
                  {line}
                </Typography>
              ))}
          </div>
        </div>

        <div className="flex">
          <Typography className="min-w-[165px] text-16 leading-6 lg:min-w-[218px]">
            {formatOrdersMessage({
              id: 'payment.method',
              defaultMessage: 'Payment method',
            })}
          </Typography>

          <Typography className="text-16 leading-6 text-neutral-4">{paymentInfo}</Typography>
        </div>
      </div>
      {order.invoiceFilename ? (
        <div>
          <Button variant="secondary" onClick={openInvoice} loading={loading}>
            {formatOrdersMessage({
              id: 'openInvoice',
              defaultMessage: 'Open Invoice',
            })}
          </Button>
        </div>
      ) : null}
    </div>
  );
};

export default OrderInfoSection;
