import React, { FC } from 'react';
import dynamic from 'next/dynamic';
import { OrderWorkflowStatus } from 'shared/types/cart/Order';
import Typography from 'components/commercetools-ui/atoms/typography';
import { useFormat } from 'helpers/hooks/useFormat';

const OrderCreated = dynamic(() => import('components/icons/orders/OrderCreated'));
const OrderPaid = dynamic(() => import('components/icons/orders/OrderPaid'));
const CollectingStarted = dynamic(() => import('components/icons/orders/CollectingStarted'));
const CollectingCompleted = dynamic(() => import('components/icons/orders/CollectingCompleted'));
const PackingCompleted = dynamic(() => import('components/icons/orders/PackingCompleted'));
const Sent = dynamic(() => import('components/icons/orders/Sent'));
const DeliveryRejected = dynamic(() => import('components/icons/orders/DeliveryRejected'));
const Delivered = dynamic(() => import('components/icons/orders/Delivered'));
const Cancelled = dynamic(() => import('components/icons/orders/Cancelled'));

interface Props {
  status: OrderWorkflowStatus | undefined;
  button: React.JSX.Element | undefined;
}

const OrderStatus: FC<Props> = ({ status, button }) => {
  const { formatMessage: formatOrdersMessage } = useFormat({ name: 'orders' });

  let orderStatus;
  let orderDescription;
  let orderProgress;

  switch (status) {
    case 'CANCELLED':
      orderStatus = formatOrdersMessage({ id: 'orderStatus.cancelled', defaultMessage: 'Order Cancelled' });
      orderDescription = formatOrdersMessage({
        id: 'orderDescription.cancelled',
        defaultMessage: 'Your order has been cancelled. Please contact customer service for details.',
      });
      orderProgress = <Cancelled />;
      break;
    case 'COLLECTING_STARTED':
      orderStatus = formatOrdersMessage({ id: 'orderStatus.collectingStarted', defaultMessage: 'Collecting started' });
      orderDescription = formatOrdersMessage({
        id: 'orderDescription.collectingStarted',
        defaultMessage: 'The order is being completed.',
      });
      orderProgress = <CollectingStarted />;
      break;
    case 'COLLECTING_COMPLETED':
      orderStatus = formatOrdersMessage({
        id: 'orderStatus.collectingCompleted',
        defaultMessage: 'Collecting completed',
      });
      orderDescription = formatOrdersMessage({
        id: 'orderDescription.collectingCompleted',
        defaultMessage: 'Your order has been completed and will be packed for you shortly.',
      });
      orderProgress = <CollectingCompleted />;
      break;
    case 'CREATED':
      orderStatus = formatOrdersMessage({
        id: 'orderStatus.created',
        defaultMessage: 'Created',
      });
      orderDescription = formatOrdersMessage({
        id: 'orderDescription.created',
        defaultMessage: 'We have received your order and are verifying your payment.',
      });
      orderProgress = <OrderCreated />;
      break;
    case 'DELIVERED':
      orderStatus = formatOrdersMessage({
        id: 'orderStatus.delivered',
        defaultMessage: 'Delivered to customer',
      });
      orderDescription = formatOrdersMessage({
        id: 'orderDescription.delivered',
        defaultMessage: 'Your order has been delivered to the address provided.',
      });
      orderProgress = <Delivered />;
      break;
    case 'DELIVERY_REJECTED':
      orderStatus = formatOrdersMessage({
        id: 'orderStatus.deliveryRejected',
        defaultMessage: 'Delivery Rejected',
      });
      orderDescription = formatOrdersMessage({
        id: 'orderDescription.deliveryRejected',
        defaultMessage:
          'Your order was not accepted, please verify with the shipping company or contact customer service.',
      });
      orderProgress = <DeliveryRejected />;
      break;
    case 'PACKING_COMPLETED':
      orderStatus = formatOrdersMessage({
        id: 'orderStatus.packingCompleted',
        defaultMessage: 'Packing completed',
      });
      orderDescription = formatOrdersMessage({
        id: 'orderDescription.packingCompleted',
        defaultMessage: 'Your order has been packed and is waiting to be collected by the courier.',
      });
      orderProgress = <PackingCompleted />;
      break;
    case 'PAID':
    case 'EXPORTED':
      orderStatus = formatOrdersMessage({
        id: 'orderStatus.paid',
        defaultMessage: 'Paid',
      });
      orderDescription = formatOrdersMessage({
        id: 'orderDescription.paid',
        defaultMessage: 'Your order has been paid and we are verifying the availability of the goods in our warehouse.',
      });
      orderProgress = <OrderPaid />;
      break;
    case 'SENT':
      orderStatus = formatOrdersMessage({
        id: 'orderStatus.sent',
        defaultMessage: 'Sent to customer',
      });
      orderDescription = formatOrdersMessage({
        id: 'orderDescription.sent',
        defaultMessage: 'Your order has been released for shipping.',
      });
      orderProgress = <Sent />;
      break;
  }

  return orderStatus ? (
    <div className="flex flex-row gap-20 bg-background p-20 lg:bg-neutral-5">
      <div className="flex flex-1 flex-col gap-8">
        <div>
          <Typography className="text-12 uppercase leading-[15px] text-neutral-4">
            {formatOrdersMessage({ id: 'status', defaultMessage: 'Status' })}
          </Typography>
          <Typography className="pt-2 text-20 font-bold leading-[25px] text-black">{orderStatus}</Typography>
        </div>
        <div>
          <Typography className="text-16 leading-[20px] text-neutral-4">{orderDescription}</Typography>
        </div>
        {button ? <div className="mt-12 flex">{button}</div> : null}
      </div>
      <div className="h-116 w-116">{orderProgress}</div>
    </div>
  ) : null;
};

export default OrderStatus;
