import { useCallback, useEffect, useState } from 'react';
import { Order } from 'shared/types/cart/Order';
import { useFormat } from 'helpers/hooks/useFormat';

const useOrderInfoData = (order?: Order) => {
  const { formatMessage: formatPaymentMessage } = useFormat({ name: 'payment' });

  const [orderNumber, setOrderNumber] = useState('');
  const [deliveryMethod, setDeliveryMethod] = useState('');
  const [shippingAddress, setShippingAddress] = useState('');
  const [paymentInfo, setPaymentInfo] = useState('');
  const [pickupPointName, setPickupPointName] = useState('');

  const updateOrderNumber = useCallback(() => {
    const label = order?.orderId?.split('-').join(' ') ?? '';
    setOrderNumber(label);
  }, [order?.orderId]);

  const updateDeliveryMethod = useCallback(() => {
    setDeliveryMethod(order?.shippingInfo?.name ?? '');
  }, [order?.shippingInfo]);

  const updateShippingAddress = useCallback(() => {
    if (order?.shippingAddress) {
      const { streetName, city, postalCode, streetNumber, country, additionalAddressInfo, externalId, company } =
        order.shippingAddress;
      const label =
        externalId && additionalAddressInfo
          ? `${additionalAddressInfo}, ${postalCode} ${city}, ${externalId}`
          : `${company}, ${streetName} ${streetNumber}, ${postalCode} ${city}, ${country}`;
      setShippingAddress(label);
    }
  }, [order?.shippingAddress]);

  const updatePaymentInfo = useCallback(() => {
    const label = formatPaymentMessage({
      id: `payment.method.${order?.paymentMethod?.toLowerCase()}`,
      defaultMessage: order?.paymentMethod,
    });
    setPaymentInfo(label);
  }, [formatPaymentMessage, order?.paymentMethod]);

  const updatePickupPointName = useCallback(() => {
    if (order?.shippingAddress?.additionalStreetInfo) {
      setPickupPointName(order.shippingAddress.additionalStreetInfo);
    }
  }, [order?.shippingAddress]);

  useEffect(() => {
    if (order) {
      updateOrderNumber();
      updateDeliveryMethod();
      updateShippingAddress();
      updatePaymentInfo();
      updatePickupPointName();
    }
  }, [order, updateDeliveryMethod, updateOrderNumber, updatePaymentInfo, updatePickupPointName, updateShippingAddress]);

  return {
    orderNumber,
    deliveryMethod,
    shippingAddress,
    paymentInfo,
    pickupPointName,
  };
};

export default useOrderInfoData;
